.carousel{
    overflow: hidden;
    position: relative;
}

.inner{
    white-space: nowrap;
    transition: transform 0.3s;
}

.carousel-item-circular {
    margin-bottom: 25px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
}

.carousel-item-circular-movil {
    margin-bottom: 25px;
    align-items: center;
    justify-content: center;
    color: #ffffff;
}

.indicators {
    display: flex;
    justify-content: center;
}

.indicators > button {
    margin: 5px;
}

.carousel-item-circular h4, .carousel-item-circular-movil h4 {
    font-size: 20px;
    margin-bottom: 25px;
    letter-spacing: 5px;
    text-transform: uppercase;
}

.btn_prev, .btn_next {
    position: absolute;
    z-index: 99;
}

.btn_prev{
    top: 40%;
    left: 29%;
    background: url("../../assets/images/btn_left.webp");
    background-size: 100%;
    width: 80px;
    height: 80px;
    font: 0/0 a;
    color: transparent;
    text-decoration: none;
}

.btn_next{
    top: 40%;
    right: 26%;
    background: url("../../assets/images/btn_right.webp");
    background-size: 100%;
    width: 80px;
    height: 80px;
    font: 0/0 a;
    color: transparent;
    text-decoration: none;
}

.btn_next:hover {
    background: url("../../assets/images/btn_left_hover.webp");
}

.btn_prev:hover {
    background: url("../../assets/images/btn_right_hover.webp");
}


.btn_circle{
    background-color: #80808e;
    font: 0/0 a;
    color: transparent;
    text-decoration: none;
    padding: 10px;
    border-radius: 15px;
}

.btn_circle.active {
    background-color: #3f291c;
}

