.main{
  height: 58px;
}

.navbar{
  display: table;
  padding-top: 0px;
  padding-bottom: 0px;
}

.navbar a{
  font-family: 'centaurregular';
  color: #fff;
  font-size: 17px;
  text-transform: uppercase;
}

.navbar a span{
  margin-top: 10px;
}

.navbar a:hover{
  color: #4258db;
}

#navbarToggleExternalContent a{
  letter-spacing: 4px;
}

.logo {
  padding-top: 1px;
}

.ad-btn{
  font-size: 15px !important;
}

.twhite{
  color: #ffffff !important;
}

.font2{
  font-family: 'Montserrat', sans-serif !important;
}

.bwhite{
  background-color: #3f291c;
  padding-top: 10px !important;
  position: relative;
  z-index: 99999!important;
}

.bwhite a{
  color: #ffffff!important;
}

.bwhite a:hover{
  color: #f0d0c3!important;
}

.banner{
  width: 100%;
}

.box{
  /*position: relative;*/
  /*display: inline-block;*/ /* Make the width of box same as image */
  width: 100%;
  /*height: 100vh;*/
  /* max-height: 824px;*/
  /*background: url('./assets/images/banner.webp') no-repeat;*/
  background-position: center;
  margin: auto;
  /*margin-bottom: 132px;*/
  background-color: #3f291c;
}
.box .text{
  color: #fff;
  /*position: absolute;*/
  z-index: 999;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 15%; /* Adjust this value to move the positioned div up and down */
  width: 100%; /* Set the width of the positioned div */
}

.box .text h1 {
  font-size: 29px;
}

.box .text h1 img {
  width: 70%;
}

.box .text span{
  font-family: 'centaurregular';
  font-size: 63px;
}

.tablaFondo{
  float: left;
  width: 100%;
  max-width: 1440px;
  height: 377px;
  margin-top: -190px;
  background: url('./assets/images/tablaFondo.webp') no-repeat;
}

.logoB{
  position: relative;
  z-index: 999;
  margin-top: -66px;
}

.b-gray{
  background-color: #eff1fb;
}

.b-mt{
  margin-top: -20px;
}

.h-head{
  padding-bottom:20px;
  border-bottom: solid 1px #e5e6e9;
}

.h-100{
  height: 100%;
}

.appbtn{
  border: none;
  background-color: #50392c;
  color: #ffffff;
  background: rgb(114,63,18);
  background: linear-gradient(180deg, rgba(114,63,18,1) 0%, rgba(87,53,25,1) 45%, rgba(61,43,32,1) 100%);
  -webkit-box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0); 
  box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0);
  font-size: 19px;
}

.appbtn2:hover{
  background-color: #7eae65;
  color: #ffffff;
}

.appbtn3:hover{
  background-color: #50392c;
  color: #ffffff;
}

.appbtn4:hover{
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.whatsappbtn{
  border: none;
  background-color: #25d366;
  color: #ffffff;
  background: rgb(37,211,102);
  background: linear-gradient(180deg, rgba(37,211,102) 0%, rgba(22,152,93,1) 45%, rgba(7,94,84,1) 100%);
  -webkit-box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0); 
  box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0);
  font-size: 19px;
}

.b-footer{
  height: 500px;
  background: url('./assets/images/b-footer.webp') no-repeat;
  background-position: center;
}

footer p, footer h6{
  color:#fff;
  margin-bottom: 0.2rem!important;
}

footer img{
  margin-right: 10px;
}

footer a{
  color:#ffffff;
  text-decoration: none;
}

footer i{
  width: 20px;
  margin-right: 12px;
}

footer a:hover{
  color: #df9559;
}

.text-small{
  font-weight: 500;
  font-size: 15px;
}

.redes_sociales a{
  font-size: 1.5em;
  padding: 10px 15px;
  border-radius: 50%;
  margin-right: 10px;
  color: #ffffff;
  background: rgb(114,63,18);
  background: linear-gradient(180deg, rgba(114,63,18,1) 0%, rgba(87,53,25,1) 45%, rgba(61,43,32,1) 100%);
  -webkit-box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0); 
  box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0);
}

.redes_sociales a:hover{
  color: #ffffff;
}

.redes_sociales i{
  margin-right: 0px;
}

.instagram:hover{
  background: linear-gradient(115deg, #f9ce34, #ee2a7b, #6228d7);
}

.facebook:hover{
  background: #3b5998;
}

#propuesta, #beneficios{
  margin-top: -3rem!important;
}

#clientes, #contacto{
  padding: 4em;
}

.navbar-toggler-icon {
  background-image: url("./assets/images/iconBar.webp");
  background-size: 80%!important;
}

.nav-pills .show>.nav-link{
  background-color: transparent!important;
}

.parallax {
  position: relative;
  /* The image used */
  background-image: url("./assets/images/parallax.jpg");

  /* Set a specific height */
  min-height: 330px; 

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.parallax h4 {
  font-size: 37px;
  letter-spacing: 6px;
}

.parallax h4, .parallax p {
  color: #ffffff;
}


.nuez{
  z-index: 99;
  /*position: absolute;*/
  position: relative;
  margin-top: -10em;
  /*left: 17em;*/
  left: 1em;
}

.flecha{
  position: absolute;
  bottom: 0;
}

.sc-gsTEea{
  width: 33%;
  min-height: 100px;
}

.b-pan {
  background: url("./assets/images/pan-integral.webp") no-repeat;
  background-position: top left;
  background-size: 20%;
  /*padding-top: 76px;*/
  margin-top: 32px;
}


.b-guirnalda{
  background: url("./assets/images/guirnalda-navidad.webp") repeat-x;
  background-size: 40%;
  background-position-x: center;
  background-position-y: 20px;
  padding-top: 10%;
}

.b-guirnalda2{
  background: url("./assets/images/guirnalda-navidad.webp") repeat-x;
  background-size: 40%;
  background-position-x: center;
  background-position-y: 0px;
  padding-top: 10%;
  margin-top: -30px;
}

.b-sin-guirnalda{
  padding-top: 40px;
  margin-top: -30px;
}

.text-gray-600{
  color: #6c757d
}

.product{
  margin-top: -10px;
}

.fixed-top{
  position: fixed!important;
  z-index: 999!important;
}

.player-wrapper {
 /* width: auto;  Reset width */
 /* height: auto;  Reset height */
  height: 0;
  overflow: hidden;
  padding-top: 56.25%; /* Percentage ratio for 16:9 */
  position: relative; /* Set to relative */
}
.player-wrapper div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.react-player div div {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.p-title{
  color: #3d2b20;
  font-size: 22px;
  line-height: 30px;
}

.p-title2{
  color: #3d2b20;
  font-size: 20px;
}

.bg-brown{
  background-color: #3d2b20;
}

/* ----------------------- Ventana Modal -----------------------*/

/*.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
}*/

.modal {
  display: block;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
  align-items: center !important;
  display: flex;
}

.modal-content {
  background-color: #d4c7be;
  margin: auto;
  border: 2px solid #5e4128;
  width: 75%;
  position: relative;
}

.modal-body {
  padding: 20px;
}

.close {
  position: absolute;
    color: #fff;
    float: right;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
    z-index: 1002;
    right: 17px;
    top: 7px;
    width: 30px;
    height: 30px;
    background: #d57b43;
    line-height: 1em;
    text-align: center;
    border-radius: 5px;
}

.close:hover,
.close:focus {
  color: black;
  background: #44291b;
  border: solid 1px #d57b43;
  text-decoration: none;
  cursor: pointer;
}

.image-mask{
  text-align: center;
}

.image-mask {
  overflow: hidden;
  width: 100%;
  /* height: 600px; Altura deseada para la imagen */
}

.modal-image {
  display: block;
  width: 100%;
  transition: transform 0.3s ease; /* Añade una transición suave al hacer zoom */
}

.zoom-controls {
  text-align: center;
  margin-top: 10px;
}

.zoom-controls button {
  margin: 0 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.modal-description{
  padding: 0px 15% 15px 15%;
}

.modal-description img {
  max-width: 100%;
}

.modal-description h2 {
  margin: 10px 0;
}

.modal-description p {
  font-size: 1.1em;
}

/* --------------------------------------- Anuncio Natilla CM -----------------------------------------*/

h1.text-amarillo-CM{
  letter-spacing: 0px;
}

h3.text-amarillo-CM{
  letter-spacing: 0px;
  font-size: 41px;
}

.text-amarillo-CM{
  color: #e48123;
}

.list-CM{
  color:#ffffff;
  font-size: 20px;
}

.bg-natilla{
  background: url("./assets/images/bg-copos-nieve.webp") repeat;
  background-color: #000919;
}

.parallax2 {
  position: relative;
  /* The image used */
  background-image: url("./assets/images/img-title-casita-mecato.webp");

  /* Set a specific height */
  min-height: 330px; 

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: repeat;
  background-size: 100%;
}

.parallax2 h4 {
  font-size: 37px;
  letter-spacing: 6px;
}

.parallax2 h4, .parallax2 p {
  color: #ffffff;
}

/* --------------------------------------- FONTS -----------------------------------------*/

.crimson-text-regular {
  font-family: "Crimson Text", serif;
  font-weight: 400;
  font-style: normal;
}

.crimson-text-semibold {
  font-family: "Crimson Text", serif;
  font-weight: 600;
  font-style: normal;
}

.crimson-text-bold {
  font-family: "Crimson Text", serif;
  font-weight: 700;
  font-style: normal;
}

.crimson-text-regular-italic {
  font-family: "Crimson Text", serif;
  font-weight: 400;
  font-style: italic;
}

.crimson-text-semibold-italic {
  font-family: "Crimson Text", serif;
  font-weight: 600;
  font-style: italic;
}

.crimson-text-bold-italic {
  font-family: "Crimson Text", serif;
  font-weight: 700;
  font-style: italic;
}


/* ---------------------------------------- Media Queries --------------------------------*/

@media only screen and (max-width: 500px) {
  #clientes, #contacto, #propuesta, #beneficios, #funcionamiento, #admin{
    padding: 2em;
  }
  h3{
    margin-top: 30px;
  }
}

@media only screen and (min-width: 1000px) {
  .navbar-toggler{
    display: none;
  }
}
@media only screen and (max-width: 1000px) {
  .box{
    /*margin-top: 69px;*/
  }
  .collapse{
    width: 100%;
    clear: both;
  }
  .box .text h1{
    font-size: 23px;
  }
  .box .text span{
    font-size: 50px;
  }
  .logo{
    float: right;
  }
}

@media only screen and (min-width: 1024px) {
  .product{
    margin-top: -20px;
  }

  .b-pan{
    padding-top: 86px;
  }
  
}

@media only screen and (max-width: 1024px) {
  .b-pan{
    /*padding-top: 200px;*/
    padding-top: 86px;
  }
}

@media only screen and (min-width: 1280px) {
  p{
    font-size: 17px;
    line-height: 25px;
  }
}

@media only screen and (max-width: 991px) {

  h4 {
    font-size: 30px;
  }

  .modal-content{
    width: 100%;
    padding: 0px;
  }

  .modal-description{
    padding: 0px 5% 15px 5%;
  }

  .modal-description p {
    font-size: 1em;
  }

  .box .text{
    top: 4%;
  }

  .b-footer {
    text-align: center;
    color: #ffffff!important;
  }

  #contacto{
    padding: 1em 4em 8em 4em;
  }

  .text-gray-600{
    color: #ffffff;
  }

  .nuez{
    left: 0em;
  }
}

@media only screen and (max-width: 700px) {
  #clientes, #contacto, #propuesta, #beneficios, #funcionamiento, #admin{
    padding: 0px 20px 89px 20px !important;
  }
  .logoB{
    margin-top: -20px;
  }
  .logoB img{
    width: 80px;
  }
  .box .text {
    top: 4%;
    text-align: center;
  }
  .box .text h1 {
    font-size: 19px;
  }
  .box .text span {
    font-size: 40px;
  }
}

@media only screen and (max-width: 426px) {
  .w-image{
    width: 40%;
  }

  .b-pan {
    background: none;
    background-size: 36%;
    padding-top: 60px;
    margin-top: 0px;
  }

  .b-guirnalda, .b-guirnalda2{
    background-size: 100%;
    background-repeat: no-repeat;
    padding-top: 27%;
    margin-top: -21px;
  }

  h2 span, h3 span {
    /*font-size: 19px;
    line-height: 21px;*/
    font-size: 19px;
    line-height: 19px;
    font-family: 'Montserrat', sans-serif;
    letter-spacing:0px;
  }

  h2 {
    /*font-size: 70px;
    line-height: 60px;*/
    font-size: 55px;
    line-height: 55px;
  }
  .box .text {
    top: 4%;
  }
  .box .text span {
    font-size: 24px;
  }
  .b-footer{
    text-align: center;
    height: auto;
    padding-bottom: 20px;
  }
}