@font-face {
  font-family: 'centaurregular';
  src: url('./assets/fonts/centaur-webfont.woff2') format('woff2'),
       url('./assets/fonts/centaur-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  color: #5a3529;
  font-size: 17px;
   /*background: url('./assets/images/lp-movil.jpg');
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center 82px;*/
}

h1,h2,h3,h4,h5,h6{
  font-family: 'centaurregular';
  color: #50392c;
}

h1, h2, h3{
  letter-spacing: 5px;
}

h2{
  font-size: 100px;
  line-height: 80px;
}

h3{
  font-size: 54px;
  line-height: 54px;
}

h4{
  font-size: 34px;
}

h6{
  font-family: 'centaurregular';
  font-size: 23px;
}

p{
  font-family: 'Montserrat', sans-serif;
  font-size: 1em;
  color: #5a3529;
  font-weight: 500;
  margin-bottom: 0.1em;
}

strong{
  font-family: 'Montserrat', sans-serif;
}

label{
  font-weight: bold;
}

button{
  font-family: 'Montserrat', sans-serif;
  font-size: 24px;
}

h1 span, h2 span, h3 span, h4 span, h5 span, h6 span {
  text-transform: uppercase;
  color: #3d3d3d;
}

h2 span, h3 span {
  font-size: 33px;
}

li{
  margin-bottom: 6px;
}

.btn-check:active+.btn-primary, .btn-check:checked+.btn-primary, .btn-primary.active, .btn-primary:active, .show>.btn-primary.dropdown-toggle{
  color: #fff;
  background-color: #7b331b !important;
  border-color: #7b331b !important;
}

.btn-primary:hover{
  color: #fff;
  background-color: #7b331b !important;
  border-color: #7b331b !important;
}

.loading{
  height: 100vh;
}

.precio {
  font-size: 2em;
  line-height: 0.5em;
}

.mensaje{
  font-size: 18px!important;
}


/* ------------------------------------------------ CSS BOOTSTRAP ----------------------------------------------------------------------------- */

*, ::after, ::before {
  box-sizing: border-box;
}

body {
  margin: 0;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}

p {
  margin-top: 0;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

img, svg {
  vertical-align: middle;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-top {
  position: fixed!important;
  z-index: 99999!important;
}

.bwhite {
  background-color: #3f291c;
  padding-top: 10px !important;
  position: relative;
  z-index: 99999!important;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: #0d6efd;
  text-decoration: none;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar {
  display: table;
  padding-top: 0px;
  padding-bottom: 0px;
}

.nav-pills .nav-link {
  background: 0 0;
  border: 0;
  border-radius: 0.25rem;
}

dl, ol, ul {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol, ul {
  padding-left: 2rem;
}

.mb-2 {
  margin-bottom: 0.5rem!important;
}

.mb-3 {
  margin-bottom: 1rem!important;
}

.mb-5 {
  margin-bottom: 3rem!important;
}

.mt-2 {
  margin-top: 0.5rem!important;
}

.mt-3 {
  margin-top: 1rem!important;
}

.mt-5 {
  margin-top: 3rem!important;
}

.p-0 {
  padding: 0!important;
}

.p-2 {
  padding: 0.5rem!important;
}

.pt-3 {
  padding-top: 1rem!important;
}

.pt-4 {
  padding-top: 1.5rem!important;
}

.pb-1 {
  padding-bottom: 0.25rem!important;
}

.pb-2 {
  padding-bottom: 0.5rem!important;
}

.pb-3 {
  padding-bottom: 1rem!important;
}


.py-2 {
  padding-top: 0.5rem!important;
  padding-bottom: 0.5rem!important;
}

.py-3 {
  padding-top: 1rem!important;
  padding-bottom: 1rem!important;
}

.px-2 {
  padding-right: 0.5rem!important;
  padding-left: 0.5rem!important;
}

.px-3 {
  padding-right: 1rem!important;
  padding-left: 1rem!important;
}

.px-4 {
  padding-right: 1.5rem!important;
  padding-left: 1.5rem!important;
}

.w-100 {
  width: 100%!important;
}

.d-block{
  display: block;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6!important;
}

.border-top-1 {
  border-top: 1px solid #3d2b20;
}

.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
  width: 100%;
  padding-right: var(--bs-gutter-x,.75rem);
  padding-left: var(--bs-gutter-x,.75rem);
  margin-right: auto;
  margin-left: auto;
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) * -.5);
  margin-left: calc(var(--bs-gutter-x) * -.5);
}

.row>* {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-top: var(--bs-gutter-y);
}

.text-start {
  text-align: left!important;
}

.text-center {
  text-align: center!important;
}

.text-end {
  text-align: right!important;
}

.text-white {
  --bs-text-opacity: 1;
  color: #fff!important;
}

.fs-6 {
  font-size: 1rem!important;
}

iframe {
  border: 0;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: bolder;
  line-height: 1.2;
}

b, strong {
  font-weight: bolder;
}

.alert-warning {
  color: #664d03;
  background-color: #fff3cd;
  border-color: #ffecb5;
}

.alert-success {
  color: #0f5132;
  background-color: #d1e7dd;
  border-color: #badbcc;
}

.alert-danger {
  color: #842029;
  background-color: #f8d7da;
  border-color: #f5c2c7;
}

.alert {
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.align-items-center {
  align-items: center!important;
}

.justify-content-center {
  justify-content: center!important;
}

.flex-column {
  flex-direction: column!important;
}

.d-flex {
  display: flex!important;
}

.d-none {
  display: none!important;
}

[type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
  cursor: pointer;
}

.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.btn-light {
  color: #ffffff;
  background-color: #6e3d13;
  border-color: #f8f9fa;
}

[type=button], [type=reset], [type=submit], button {
  -webkit-appearance: button;
}

button, select {
  text-transform: none;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

input[type="text"], 
input[type="email"], 
input[type="tel"], 
select, 
textarea {
  height: 60px;
  width: 100%;
  background-color: #e8e7e9;
  border: none;
  outline: none;
  padding-left: 30px;
  padding-right: 55px;
  color: #50331e;
  font-size: 20px;
  border-radius: 5px;
}


/* -------- input select -------*/

.content-select{
	/*position: relative;
  display: inline-block;
  vertical-align: top;*/
}

.content-select select{
  height: 78px;
  color: #ffffff;
  background: rgb(114,63,18);
  background: linear-gradient(180deg, rgba(114,63,18,1) 0%, rgba(87,53,25,1) 45%, rgba(61,43,32,1) 100%);
  -webkit-box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0); 
  box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0);
	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
  display: inline-block;
  position: relative;
  cursor: pointer;
  outline: 0!important;
  transition: all 0.25s ease;
  padding-left: 90px;
  
}

/* Eliminamos la fecha que por defecto aparece en el desplegable */
.content-select select::-ms-expand {
  display: none;
}

.content-select select:hover{
	background: #3d2b20;
}

.content-select select option:checked{
  background: rgb(114,63,18);
  background: linear-gradient(180deg, rgba(114,63,18,1) 0%, rgba(87,53,25,1) 45%, rgba(61,43,32,1) 100%);
}

.content-select select option:hover{
  background: #ffffff;
  color: rgb(114,63,18);
}

/* 
Creamos la fecha que aparece a la izquierda del select.
Realmente este elemento es un cuadrado que sólo tienen
dos bordes con color y que giramos con transform: rotate(-45deg);
*/
.content-select i{
	position: absolute;
	right: 20px;
	top: calc(50% - 13px);
	width: 16px;
	height: 16px;
	display: block;
	border-left:4px solid #ffffff;
	border-bottom:4px solid #FFFFFF;
	transform: rotate(-45deg); /* Giramos el cuadrado */
	transition: all 0.25s ease;
  pointer-events: none;
}

.content-select:hover i{
	margin-top: 8px;
  transform: rotate(-225deg); /* Giramos el cuadrado */
	transition: all 0.25s ease;
}

.control {
  box-sizing: border-box;
  clear: both;
  position: relative;
  text-align: inherit;
}

.icon-select{
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  z-index: 4;
  align-items: center;
  display: inline-flex;
  top: 8px;
  left: 20px;
}

/* --------- card ---------------------*/


.card-img, .card-img-bottom, .card-img-top {
  width: 100%;
}

/* --------- media queries ---------------------*/


@media (max-width: 576px){
  .container, .container-sm {
    max-width: 540px;
  }

  .flex-sm-row {
    flex-direction: row!important;
  }

  .col-sm {
      flex: 1 0 0%;
  }

  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333337%;
  }

  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .flex-sm-row {
      flex-direction: row!important;
  }

  .text-sm-center {
    text-align: center!important;
  }
}


@media (min-width: 426px){
  .container, .container-md, .container-sm {
    max-width: 720px;
  }

  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333337%;
  }

  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
}

  .text-md-start {
    text-align: left!important;
  }
  
  
}

@media (min-width: 992px){
  .container, .container-lg, .container-md, .container-sm {
    max-width: 960px;
  }

  .col-lg {
    flex: 1 0 0%;
  }

  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .text-lg-start {
    text-align: left!important;
  }
  
}

@media (min-width: 1200px){
  .container, .container-lg, .container-md, .container-sm, .container-xl {
      max-width: 1140px;
  }
}

@media (min-width: 1400px){
  .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
      max-width: 1320px;
  }
}
